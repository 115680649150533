import { Controller } from '@hotwired/stimulus'

export default class BalanceSheetController extends Controller {
  static targets = ['mainView', 'edit', 'undo', 'dataInput', 'visibilitySelect',
                    'currentYearValue', 'currentYearPercentage', 'previousYearValue', 'previousYearPercentage', 'differenceValue', 'differencePercentage'
                   ]

  static values = {
    data: Object,
    field: String,
    hasPreviousYear: Boolean
  }

  connect() {
    console.log(this.dataValue)
    console.log(this.fieldValue)
    this.dataJSON = this.dataValue
    this.unsubscribe = []

    if (!this.dataJSON[this.fieldValue]) {
      this.dataJSON[this.fieldValue] = {}
    }
    if (!this.dataJSON[this.fieldValue].renamed) {
      this.dataJSON[this.fieldValue].renamed = {}
    }

    this.editTargets.forEach((e) => {
      const handler = this.onEdit.bind(this)
      e.addEventListener('change', handler)
      this.unsubscribe.push([e, 'change', handler])

      // Load the data from the data object
      const rowId = e.getAttribute('data-financial-report--balance-sheet-id')
      if (this.dataJSON[this.fieldValue]?.renamed?.[rowId]) {
        e.value = this.dataJSON[this.fieldValue].renamed[rowId]
        this.showUndoButton(e)
      }
    })

    this.visibilitySelectTargets.forEach((e) => {
      const columnKey = e.dataset['financialReport-BalanceSheetColumnKey']
      const visibilityData = this.dataJSON.visibility
      if (visibilityData && visibilityData[columnKey]) {
        e.value = visibilityData[columnKey]
      }
    })

    this.visibilitySelectTargets.forEach((e) => {
      const handler = this.onVisibilityChange.bind(this)
      e.addEventListener('change', handler)
      this.unsubscribe.push([e, 'change', handler])
    })

    const visibilityData = this.dataJSON.visibility;
    if (visibilityData) {
      Object.keys(visibilityData).forEach(columnKey => {
        const visibilityType = visibilityData[columnKey];
        this.applyGrayOutEffect(columnKey, visibilityType);
      });
    }

    this.undoTargets.forEach((e) => {
      const handler = this.onUndo.bind(this)
      e.addEventListener('click', handler)
      this.unsubscribe.push([e, 'click', handler])
    })

    this.updateData()
  }

  disconnect() {
    this.unsubscribe.forEach((u) => {
      u[0].removeEventListener(u[1], u[2])
    })
  }

  onVisibilityChange(e) {
    console.log(e)
    const columnKey = e.target.dataset['financialReport-BalanceSheetColumnKey']
    const value = e.target.value

    if (!this.dataJSON.visibility) {
      this.dataJSON.visibility = {}
    }

    this.dataJSON.visibility[columnKey] = value

    if (columnKey === 'previous_year' && value === 'excluded') {
      this.dataJSON.visibility['difference'] = 'excluded'
      const diffInput = this.visibilitySelectTargets.find(e => e.dataset['financialReport-BalanceSheetColumnKey'] === 'difference')
      diffInput.value = 'excluded'
      this.applyGrayOutEffect('difference', 'excluded')
    }

    this.updateData()
    this.applyGrayOutEffect(columnKey, value)
  }

  applyGrayOutEffect(columnKey, visibilityType) {
    const columnTargetMap = {
      'current_year': ['currentYearValue', 'currentYearPercentage'],
      'previous_year': ['previousYearValue', 'previousYearPercentage'],
      'difference': ['differenceValue', 'differencePercentage']
    }

    const affectedTargets = columnTargetMap[columnKey]

    const valueCells = this[`${affectedTargets[0]}Targets`]
    const percentageCells = this[`${affectedTargets[1]}Targets`]

    const excludedClass = 'text-gray-200'

    switch (visibilityType) {
      case 'visible':
        valueCells.forEach(cell => cell.classList.remove(excludedClass))
        percentageCells.forEach(cell => cell.classList.remove(excludedClass))
        break
      case 'no_percentage':
        valueCells.forEach(cell => cell.classList.remove(excludedClass))
        percentageCells.forEach(cell => cell.classList.add(excludedClass))
        break
      case 'excluded':
        valueCells.forEach(cell => cell.classList.add(excludedClass))
        percentageCells.forEach(cell => cell.classList.add(excludedClass))
        break
    }
  }

  onEdit(e) {
    const rowId = e.target.getAttribute('data-financial-report--balance-sheet-id')
    const defaultValue = e.target.getAttribute('data-financial-report--balance-sheet-default-value')

    if (e.target.value !== defaultValue) {
      this.showUndoButton(e.target)

      this.dataJSON[this.fieldValue].renamed[rowId] = e.target.value
    } else {
      this.hideUndoButton(e.target)
      delete this.dataJSON[this.fieldValue].renamed[rowId]
    }

    this.updateData()
  }

  onUndo(e) {
    const editButton = e.target.closest('[data-financial-report--balance-sheet-element="container"]')
                               .querySelector('[data-financial-report--balance-sheet-target="edit"]')

    const rowId = editButton.getAttribute('data-financial-report--balance-sheet-id')
    const defaultValue = editButton.getAttribute('data-financial-report--balance-sheet-default-value')

    editButton.value = defaultValue
    this.hideUndoButton(editButton)
    delete this.dataJSON[this.fieldValue].renamed[rowId]

    this.updateData()
  }

  showUndoButton(inputElement) {
    const undoButton = inputElement.closest('[data-financial-report--balance-sheet-element="container"]')
                                   .querySelector('[data-financial-report--balance-sheet-target="undo"]')
    undoButton.classList.remove('hidden')
  }

  hideUndoButton(inputElement) {
    const undoButton = inputElement.closest('[data-financial-report--balance-sheet-element="container"]')
                                   .querySelector('[data-financial-report--balance-sheet-target="undo"]')
    undoButton.classList.add('hidden')
  }

  updateData() {
    console.log(this.dataJSON)

    this.dataInputTarget.value = JSON.stringify(this.dataJSON)
  }

}
