// This class links to the brain cell, which is the actual data.
// It manages both its DOM counterparts and the events.
// DOM counterparts include the cell when edited and the cell when not edited: viewer and editor.
//
// To avoid writing HTML in JS, it uses a template element, where it copies the HTML
// from;
export default class RenderedCell {
  constructor(brain, brainCell, templatesElement, columnConfig, isHeader, columnIndex) {
    this.format = brainCell.format;
    this.style = brainCell.style;
    this.brain = brain;
    this.brainCell = brainCell;
    this.templatesElement = templatesElement;
    this.columnConfig = columnConfig;
    this.isHeader = isHeader;
    this.columnIndex = columnIndex

    // This is an empty div that will be used to host the editor and the viewer
    // One of them will always the hidden, other one shown, depending on the mode.
    this.rootElement = document.createElement('div');
    if (this.columnConfig.width) {
      this.rootElement.style.width = this.columnConfig.width;
    } else {
      this.rootElement.style.width = '100%';
    }

    this.rootElement.classList.add('h-full', 'relative');

    if (this.brainCell.editable) {
      this.buildEditor()
      this.input = this.editor.querySelector('input');

      this.input.addEventListener('keydown', (e) => {
        if (e.keyCode === 13) {
          this.input.blur();
        }
      })
    }
    this.buildViewers()

    if (this.brainCell.editable) {
      this.addClickHandler()
      this.addInputBlurHandler()
    }

    this.updateViewer();

    if (isHeader) {
      this.processDeleteButton(this.columnConfig.deletable)
    }

    this.brainCell.subscribe('change', (value) => {
      this.updateViewer();
    })
  }

  buildEditor() {
    const template = this.templatesElement.querySelector(`[data-meow-template="cell-editor"]`);
    this.editor = template.cloneNode(true);
    this.input = this.editor.querySelector('input');

    if (this.format.type == 'date') {
      this.input.setAttribute('type', 'date');
    }

    this.editor.classList.add('hidden');
    this.rootElement.appendChild(this.editor);
  }

  buildViewers() {
    this.viewers = {};

    ['viewer', 'viewer-error'].forEach(key => {
      const templateKey = this.isHeader ? `cell-header-${key}` : `cell-${key}`;
      const template = this.templatesElement.querySelector(`[data-meow-template="${templateKey}"]`);
      this.viewers[key] = template.cloneNode(true);

      switch(this.columnConfig.align) {
        case 'right':
          this.viewers[key].classList.add('text-right');
          break;
        default:
          this.viewers[key].classList.add('text-left');
          break;
      }

      if (this.style) {
        if (this.style.bold) {
          this.viewers[key].classList.add('font-bold');
        }
      }

      this.rootElement.appendChild(this.viewers[key]);
    })
  }

  addClickHandler() {
    ['viewer', 'viewer-error'].forEach(key => {
      this.viewers[key].querySelector('[data-meow-role=cell-value]').addEventListener('click', () => {
        switch(this.format.type) {
          case 'text':
            this.input.value = this.brainCell.value;
            break;
          case 'money':
            this.input.value = (this.brainCell.value / 100).toFixed(2);
            break;
          case 'percentage':
            this.input.value = this.brainCell.value;
            break;
          case 'date':
            this.input.value = this.brainCell.value ? new Date(this.brainCell.value).toISOString().split('T')[0] : '';
            break;
          default:
            throw new Error(`Unknown formatter type: ${this.format.type}`);
        }

        this.editor.classList.remove('hidden');
        this.hideViewers();
        this.input.focus();
      });
    });
  }


  addInputBlurHandler() {
    this.input.addEventListener('blur', () => {
      switch(this.format.type) {
        case 'text':
          this.brainCell.changeValue(this.input.value);
          break;
        case 'money':
          const intVal = Math.round(parseFloat(+this.input.value) * 100);
          this.brainCell.changeValue(intVal);
          break;
        case 'percentage':
          const floatVal = parseFloat(this.input.value);
          this.brainCell.changeValue(floatVal);
          break;
        case 'date':
          // brainCell.value must be a Rails postgresql timestamp string with only date component
          const dateVal = this.input.value ? new Date(this.input.value).toISOString().split('T')[0] : null;
          this.brainCell.changeValue(dateVal);
          break;
        default:
          throw new Error(`Unknown formatter type: ${this.format.type}`);
      }

      this.editor.classList.add('hidden');
      this.updateViewer()
    });

    // Also, when user types or copypastes anything other than numbers or a dot, in case of money
    // we want to strip it out.
    this.input.addEventListener('input', () => {
      switch(this.format.type) {
        case 'text':
          break;
        case 'money':
          this.input.value = this.input.value.replace(/[^0-9.-]/g, '');
          break;
        case 'percentage':
          this.input.value = this.input.value.replace(/[^0-9.]/g, '');
          break;
        case 'date':
          break;
        default:
          throw new Error(`Unknown formatter type: ${this.format.type}`);
      }
    })
  }

  hideViewers() {
    this.viewers['viewer'].classList.add('hidden');
    this.viewers['viewer-error'].classList.add('hidden');
  }

  showViewers() {
    this.viewers['viewer'].classList.remove('hidden');
    this.viewers['viewer-error'].classList.remove('hidden');
  }

  updateViewer() {
    this.viewers['viewer'].classList.add('hidden');
    this.viewers['viewer-error'].classList.add('hidden');

    if (this.brainCell.hasExpectationError) {
      this.viewers['viewer-error'].classList.remove('hidden');
      const cellValueEl = this.viewers['viewer-error'].querySelector('[data-meow-role=cell-value]');
      const expectedEl = this.viewers['viewer-error'].querySelector('[data-meow-role=error-message]');

      let expectedVal = '';

      switch(this.format.type) {
        case 'text':
          cellValueEl.innerHTML = this.brainCell.value;
          expectedVal = this.brainCell.expectedValue;
          break;
        case 'money':
          cellValueEl.innerHTML = this.formatMoney(this.brainCell.value);
          expectedVal = this.formatMoney(this.brainCell.expectedValue);
          break;
        case 'percentage':
          cellValueEl.innerHTML = `${this.brainCell.value}%`;
          expectedVal = `${this.brainCell.expectedValue}%`;
          break;
        case 'date':
          cellValueEl.innerHTML = this.formatDate(new Date(this.brainCell.value));
          expectedVal = this.formatDate(new Date(this.brainCell.expectedValue));
          break;
        default:
          throw new Error(`Unknown formatter type: ${this.format.type}`);
      }

      let errorSymbol = this.brainCell.expectationErrorSymbol();
      expectedEl.innerHTML = `${errorSymbol} ${expectedVal}`;
    } else {
      this.viewers['viewer'].classList.remove('hidden');
      const valueContainer = this.viewers['viewer'].querySelector('[data-meow-role=cell-value]');

      switch(this.format.type) {
        case 'text':
          valueContainer.innerHTML = this.brainCell.value;
          break;
        case 'money':
          valueContainer.innerHTML = this.formatMoney(this.brainCell.value);
          break;
        case 'percentage':
          valueContainer.innerHTML = `${this.brainCell.value}%`;
          break;
        case 'date':
          valueContainer.innerHTML = this.formatDate(new Date(this.brainCell.value));
          break;
        default:
          throw new Error(`Unknown formatter type: ${this.format.type}`);
      }
    }
  }

  formatDate(date) {
    // DD.MM.YYYY
    // Like 04.02.2024
    return `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()}`;
  }

  formatMoney(cents) {
    var s = (cents / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");

    if (s.indexOf('.') == -1) {
      s = s + '.00'
    }

    return this.format.symbol?.length ? `${this.format.symbol} ${s}` : s;
  }

  processDeleteButton(canDelete) {
    const deleteButton = this.viewers['viewer'].querySelector(`[data-meow-template="delete-column"]`);

    if (canDelete) {
      deleteButton.classList.remove('hidden');
      deleteButton.addEventListener('click', () => {
        this.brain.deleteColumn(this.columnIndex);
      });
    } else {
      deleteButton.remove();
    }
  }
}
