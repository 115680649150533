// Here we define a class that would represent the MeowMatrix data system
// Basically it contains all the logic related to storing and calculating the data

// There is nothing here related to visual UI, to validations, to user input, etc.
// So, for example, if a user types a string into a numeric field — here we just pass "invalid: true"
// and all the calculations that use invalid values will be marked as invalid too.

// Also, the formatting is out of scope here, we only operate on strings and integers here.


import Cell from './cell'

class Brain {
  constructor(config) {
    this.config = JSON.parse(JSON.stringify(config))

    const columnRow = this.buildColumnsRow()
    this.config.rows.unshift(columnRow)
    this.rows = this.config.rows.map((row, i) => this.buildRow(row, i))
    this.columns = JSON.parse(JSON.stringify(this.config.columns))

    // TODO: now we need to build the dependency graph, given each cell has a dependencies and dependents array
    this.buildDependencyGraph()
    this.evaluateCells()

    this.history = []
    this.historyIndex = 0
    this.eventListeners = []
    this.saveData = {}

    this.recordHistory()
    this.checkExpectations()
    this.saveState()
  }

  serialize() {
    return {
      features: this.config.features,
      rows: this.rows.map(row => {
        return {
          ...row,
          cells: row.cells.map(cell => cell.serialize())
        }
      }),
      columns: this.columns
    }
  }

  recordHistory() {
    this.history = this.history.slice(0, this.historyIndex + 1)
    this.history.push(JSON.parse(JSON.stringify(this.serialize())))
    this.historyIndex = this.history.length - 1

    this.notify('history-changed')
  }

  undo() {
    if (this.historyIndex > 0) {
      this.loadHistory(this.historyIndex - 1)
    }
  }

  redo() {
    if (this.historyIndex < this.history.length - 1) {
      this.loadHistory(this.historyIndex + 1)
    }
  }

  canUndo() {
    return this.historyIndex > 0
  }

  canRedo() {
    return this.historyIndex < this.history.length - 1
  }

  loadHistory(index) {
    this.historyIndex = index
    const state = this.history[this.historyIndex]

    this.config = JSON.parse(JSON.stringify(state))
    this.rows = this.config.rows.map((row, i) => this.buildRow(row, i))
    this.columns = this.config.columns

    this.buildDependencyGraph()
    this.evaluateCells()

    this.scheduleSave()
    this.notify('history-restored')
  }

  notify(event, payload) {
    this.eventListeners.filter(l => l.event === event).forEach(l => l.callback(payload));
  }

  subscribe(event, callback) {
    this.eventListeners.push({ event, callback });
  }

  buildRow(row, index) {
    return {
      ...row,
      cells: this.buildCells(row.cells, row, index)
    }
  }

  buildCells(cells, row, rowIndex) {
    return cells.map((cell, cellIndex) => {
      const letter = String.fromCharCode(65 + cellIndex)
      const id = `${letter}${rowIndex + 1}`
      return new Cell(this, cell, row, id)
    })
  }

  buildColumnsRow() {
    return {
      cells: this.config.columns.map((column, i) => {
        return {
          value: column.title,
          editable: !!column.editable,
          columnKey: column.key
        }
      }),
      added_cell: { value: 'New column', editable: true, columnKey: 'user_$COLUMN_KEY$' }
    }
  }

  allCells() {
    const all = []

    this.rows.forEach(row => {
      row.cells.forEach(cell => {
        all.push(cell)
      })
    })

    return all
  }

  buildDependencyGraph() {
    const allCells = this.allCells()

    this.rows.forEach((row, rowIndex) => {
      row.cells.forEach(cell => {
        const formulas = []

        if (cell.formula) {
          formulas.push(cell.formula)
        }

        if (cell.expectedFormula) {
          formulas.push(cell.expectedFormula)
        }

        formulas.forEach(formula => {
          formula.terms.forEach(term => {
            const dependencies = allCells.filter(c => {
              return term.tags.every(tag => c.tags.includes(tag))
            })
            term.dependencies = dependencies
            cell.dependencies.push(...dependencies)
            dependencies.forEach(d => d.dependents.push(cell))
          })
        })
      })
    })

    this.detectCircularDependencies()
  }

  detectCircularDependencies() {
    // Cells that have circular dependencies have their `valid` field set to false to
    // show an excel-like !REF! error in UI

    const visited = new Set();
    const recStack = new Set();

    const hasCycle = (cell) => {
      if (recStack.has(cell)) {
        return true;
      }
      if (visited.has(cell)) {
        return false;
      }

      visited.add(cell);
      recStack.add(cell);

      for (let dependency of cell.dependencies) {
        if (hasCycle(dependency)) {
          return true;
        }
      }

      recStack.delete(cell);
      return false;
    }

    this.rows.forEach(row => {
      row.cells.forEach(cell => {
        cell.circular = hasCycle(cell)
      })
    })
  }

  moveRow(row, positionChange) {
    const currentIndex = this.rows.indexOf(row);
    const newIndex = currentIndex + positionChange;

    if (newIndex < 0 || newIndex >= this.rows.length) {
      return;
    }

    this.rows.splice(currentIndex, 1);
    this.rows.splice(newIndex, 0, row);

    this.notify('row-moved', { from: currentIndex, to: newIndex });

    console.log("New rows: ", this.rows);

    this.recordHistory();
    this.scheduleSave()
    this.checkExpectations()
  }

  addRow(inserter, position) {
    const key = +new Date()

    const cfg = {
      ...inserter,
      tags: inserter.tags.map(tag => {
        return tag.replace('$INSERTER_KEY$', `user-${key}`)
      }),
      inserter: false,
      cells: JSON.parse(JSON.stringify(inserter.inserter_cells)),
      section: inserter.section,
      key: `user-${key}`
    }
    cfg.cells.forEach(cell => {
      if (cell.formula) {
        cell.formula.terms.forEach(term => {
          term.tags = term.tags.map(tag => {
            return tag.replace('$INSERTER_KEY$', `user-${key}`)
          })
        })
      }
    })
    const row = this.buildRow(cfg, position, true)

    this.rows.splice(position, 0, row)
    this.buildDependencyGraph()

    row.cells.forEach(cell => {
      cell.invalidate()
    })

    this.evaluateCells()

    console.log("New rows: ", this.rows)

    this.notify('row-added', { row, position })
    this.recordHistory();
    this.scheduleSave()
    this.checkExpectations()
  }

  deleteRow(row) {
    row.cells.forEach(cell => {
      cell.invalidate()
    })

    const index = this.rows.indexOf(row)
    this.rows.splice(index, 1)

    this.buildDependencyGraph()
    this.evaluateCells()

    console.log("New rows: ", this.rows)

    this.notify('row-deleted', { row, position: index - 1 })
    this.recordHistory();
    this.scheduleSave()
    this.checkExpectations()
  }

  addColumn(settings) {
    const columnId = +new Date()

    const columnConfig = {
      ...settings,
      type: this.config.features.add_column_type,
      key: `user_${columnId}`
    }
    this.columns.splice(this.config.features.add_column_index, 0, columnConfig)

    // Each row in the config has a field called `added_cell`.
    // It is a template for a cell that will be added to the row when the column is added.
    // Both its tags and tags in its formula terms contain $COLUMN_KEY$ placeholder that is replaced
    // with the column identifier.
    // This way we can add a column with a formula that references the column itself.

    const invalidate = []

    this.rows.forEach((row, i) => {
      const cellConfig = {
        ...row.added_cell,
        key: `user_${columnId}`,
        tags: (row.added_cell.tags || []).map(tag => {
          return tag.replace('$COLUMN_KEY$', columnId)
        })
      }

      if (cellConfig.columnKey) {
        cellConfig.columnKey = cellConfig.columnKey.replace('$COLUMN_KEY$', columnId)
      }

      // Also add the row common tags, same as with usual cells:
      if (row.tags) {
        cellConfig.tags = row.tags.concat(cellConfig.tags);
      }

      if (cellConfig.formula) {
        cellConfig.formula.terms.forEach(term => {
          term.tags = term.tags.map(tag => {
            return tag.replace('$COLUMN_KEY$', columnId)
          })
        })
      }

      if (row.inserter) {
        row.inserter_cells.splice(this.config.features.add_column_index, 0, cellConfig)
      } else {
        const cell = new Cell(this, cellConfig, row, `C${columnId}R${row.id}`)
        this.rows[i].cells.splice(this.config.features.add_column_index, 0, cell)

        invalidate.push(cell)
      }
    })

    this.buildDependencyGraph()

    invalidate.forEach(cell => {
      cell.invalidate()
    })

    this.evaluateCells()

    this.notify('column-added', { column: this.columns[this.columns.length - 1] })

    console.log("New columns: ", this.columns)
    this.recordHistory();
    this.scheduleSave()
    this.checkExpectations()
  }

  deleteColumn(index) {
    this.columns.splice(index, 1)

    this.rows.forEach((row, i) => {
      if (row.inserter) {
        row.inserter_cells.splice(index, 1)
      } else {
        this.rows[i].cells[index].invalidate()
        this.rows[i].cells.splice(index, 1)
      }
    })

    this.buildDependencyGraph()
    this.evaluateCells()

    this.notify('column-deleted', { index })

    console.log("New columns: ", this.columns)
    this.recordHistory()
    this.scheduleSave()
    this.checkExpectations()
  }

  evaluateCells() {
    this.allCells().filter(cell => !cell.evaluated).forEach(cell => cell.evaluate())
  }

  onCellChanged(cell) {
    if (cell.columnKey) {
      this.columns.forEach(column => {
        if (column.key === cell.columnKey) {
          column.title = cell.value
        }
      })
    }

    this.evaluateCells()
    this.recordHistory()
    this.scheduleSave()
    this.checkExpectations()
  }

  saveState() {
    const columns = this.columns.map(col => {
      return { key: col.key, title: col.title, type: col.type, deletable: col.deletable };
    });

    const rows = this.rows.filter(row => row.key).map(row => {
      const serializedRow = { key: row.key, title: row.title };
      if (row.section) {
        serializedRow.section = row.section
      }

      row.cells.forEach(cell => {
        if (cell.key) {
          serializedRow[cell.key] = cell.value;
        }
      });
      return serializedRow;
    });

    this.saveData = { columns, rows };
    this.notify('data-changed', this.saveData);
  }

  scheduleSave() {
    this.saveState()
    this.notify('save-requested', this.saveData);
  }

  checkExpectations() {
    // If any of the cells in any rows have hasExpectationError set to true
    this.hasExpectationErrors = this.rows.some(row => {
      return row.cells.some(cell => cell.hasExpectationError)
    })
    this.notify('expectation-errors-changed', { hasExpectationErrors: this.hasExpectationErrors });
  }
}

export default Brain
